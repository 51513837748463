// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

/* Variables */

$body-bg: rgb(237,237,237);
$primary-colour: rgb(241,45,94);
$primary-colour-darkened: rgb(245,87,126);
$header-colour: rgb(42,42,50);
$pink-gradient: linear-gradient(44deg, #FF4F64 0%, #A90041 94%);
$ar-gradient: linear-gradient(-135deg, #FFFFFF 0%, #0069FF 0%, #40A3FF 100%);
$qr-gradient: linear-gradient(-135deg, #FFFFFF 0%, rgb(255, 131, 36) 0%, rgba(255,164,21,1) 100%);
$sj-gradient: linear-gradient(-135deg, #FFFFFF 0%, rgb(220, 58, 58) 0%, rgb(255, 58, 58) 100%);
$vr-gradient: linear-gradient(-135deg, #FFFFFF 0%, rgb(116, 187, 46) 0%, rgb(139, 216, 63) 100%);
$dm-gradient: linear-gradient(-135deg, #FFFFFF 0%, #852ebb 0%, #b93fd8 100%);
$grey1: rgb(62,62,62);
$grey2: rgb(111,111,111);
$grey3: rgb(200,200,200);
$heading-font: "brandon-grotesque", sans-serif;
$paragraph-font: "Lato", sans-serif;
$green: rgb(129,221,28);
$green-hover: rgb(119,211,18);
$red: rgb(232,48,48);
$red-hover: rgb(222,38,38);
$lightblue: #40a3ff;
$orange: #fda400;

$welcome-paragraph-line-height: 1.8em;
$welcome-paragraph-font-size: 1.3em;

.spacer {
	height: 50px !important;
	min-height: 50px !important;
	display: block !important;
	width: 10px;
	padding-top: 15px;
	padding-bottom: 15px;
}

iframe {
	border: none !important;
	margin: 0 auto;
}

/* Typography ********************************************************************************************/

.mailto {
	color: $primary-colour;
}

h1 {
	font-family: $heading-font;
	text-transform:uppercase;
	font-size: 2em;
	font-weight: 700 !important;
	letter-spacing: -0.01em;
}

h2 {
	font-family: $heading-font;
	font-size: 1.5em;
	text-transform: uppercase;
	font-weight: 700;
	-webkit-font-smoothing:antialiased;
	margin: 0;
	color: rgb(82,82,82);
	text-align: center;
}

h3 {
	font-family: $heading-font;
	font-size: 1.2em;
	text-transform: uppercase;
	font-weight: 700;
	margin-top: 0;
	margin-bottom: 25px;
	-webkit-font-smoothing:antialiased;
	color: $grey2;
}

h4 {
	font-family: $heading-font;
	font-size: 1.5em;
	text-transform: uppercase;
	font-weight: 700;
	text-align:center;
	margin-top: 0;
	margin-bottom: 10px;
	-webkit-font-smoothing:antialiased;
	color: $grey1;	
}


h5 {
	text-align:center;
	font-family: $heading-font;
	color: rgb(169,169,169);
	-webkit-font-smoothing:antialiased;
	text-transform: uppercase;
}


/* Welcome ********************************************************************************************/

.welcome-page {
	background: white !important;
	.hero {
		padding-top: 15px;
		background-image: $pink-gradient;
		padding-bottom: 50px;
		min-height: 600px;
		.nav {
			li,a {
				cursor: pointer;
				font-weight: 600;
			}
		}
		.logo {
				width: 80px;
				margin-left: -5px;
				display:inline-block;
			}
		h1 {
			color: white;
			text-transform: uppercase;
			font-weight: 700 !important;
			-webkit-font-smoothing:antialiased !important;
			line-height:1.4;
			font-size:2.25em;
		}
		p {
			color: rgba(255,255,255,0.8);
		}
		ol li {
			font-family: $paragraph-font;
			font-size: 1.2em;
			color: rgba(255,255,255,0.9);
			line-height: 1.5;
			-webkit-font-smoothing:antialiased;
		}

	}
	.navbar {
		background: none !important;
	}
	.navbar-brand {
		padding: 0;
		color: white;
		-webkit-font-smoothing: antialiased;
		font-size:2em;
		font-weight:700;

	}
	.one {
		-webkit-font-smoothing:antialiased;
		background: rgb(249,249,249);
		text-align:center;
		padding-bottom: 50px;
		padding-top: 30px;
		border-bottom:1px solid #DDD;
		h1 {
			color: $grey1;
			text-transform: uppercase;
			font-family: $heading-font;
			font-size: 2em;
			font-weight: 700 !important;
			letter-spacing: -0.01em;
		}
		h2 {
			font-family: $paragraph-font;
			color: $grey2;
			font-size: 1.6em;
			text-transform: inherit;
			font-weight: 400;
			padding-top:20px;
		    line-height: 1.6em;
		}
	}
	.two {
		padding-top: 50px;
		padding-bottom: 50px;
		.left {
			h1 {
				font-family: $heading-font;
				color: $primary-colour-darkened;
				font-size: 2em;
				text-transform:uppercase;
				-webkit-font-smoothing:antialiased;
				font-weight: 700 !important;
				line-height: 1.3;
			}
			p {
				font-family: $paragraph-font !important;
				color: rgba(0,0,0,0.7);
				line-height: $welcome-paragraph-line-height;
				font-size: $welcome-paragraph-font-size;
				-webkit-font-smoothing:antialiased;
				strong {
					color:$primary-colour;
				}
			}
		}
	}
	.three {
		background: $pink-gradient;
		padding-top: 50px;
		padding-bottom: 50px;
		.right {
			h1 {
				font-family: $heading-font;
				color: white;
				font-size: 2em;
				text-transform:uppercase;
				-webkit-font-smoothing:antialiased;
				font-weight: 700 !important;
				line-height: 1.3;
			}
			p {
				font-family: $paragraph-font !important;
				color: rgba(255,255,255,0.8);
				line-height: $welcome-paragraph-line-height;
				font-size: $welcome-paragraph-font-size;
				-webkit-font-smoothing:subpixel-antialiased;
			}
		}
		.subject-stats-row {
			padding-top:30px;
			.subject-stats {
			    border: 2px solid #FFF;
			    border-radius: 4px;
			    padding-top: 10px;
			    padding-bottom: 10px;
			    padding-left: 10px;
			    padding-right: 10px;
			    text-align: center;
			    color: #FFF;
			    margin-bottom:20px;
			    .number {
			    	font-size:2.5em;
			    }
			    .subject {
			    	font-size:1.3em;
			    	font-family:$heading-font;
			    	text-transform:uppercase;
			    }
			    .questions {
			    	font-size:1.4em;
			    	margin-top:0px;
		    	    -webkit-font-smoothing: antialiased;
		    	    font-family:$heading-font;
		    	    text-transform:uppercase;
			    }
			}
		}
	}
	.four {
		padding-top: 50px;
		padding-bottom: 50px;
		.left {
			h1 {
				font-family: $heading-font;
				color: $primary-colour-darkened;
				font-size: 2em;
				text-transform:uppercase;
				-webkit-font-smoothing:antialiased;
				font-weight: 700 !important;
				line-height: 1.3;
			}
			p {
				font-family: $paragraph-font !important;
				color: rgba(0,0,0,0.7);
				line-height: $welcome-paragraph-line-height;
				font-size: $welcome-paragraph-font-size;
				-webkit-font-smoothing:antialiased;
			}
		}
	}
	.five {
		padding-top:50px;
		padding-bottom:50px;
		background-color:#fbfbfb;
		border-top:1px solid #DDD;
		.h1 {
			color:$primary-colour-darkened;
			text-align:center;
		}
	}
}

.completed-box {
	text-align:center;
	margin-bottom: 10px;
	font-size: 1.1em;
	-webkit-font-smoothing:antialiased;
}

/* Standard Elements ********************************************************************************************/

#app-layout {
	.navbar {
		background: $header-colour; 
		border: none;
		border-radius: 0px;
		font-family: $heading-font;
		padding-top:9px;
		padding-bottom: 9px;
		.logo {
			width: 175px;
		}
		.dropdown-toggle {
			background: none !important;
		}
		.dropdown-menu {
			background: #2a2a32;
		}
		.navbar-nav li {
			a {
				color: rgba(255,255,255,0.6);
				font-size: 1.1em;
				font-weight: 800;
				-webkit-font-smoothing:antialiased;
				text-transform:uppercase;
			}
			a:hover {
				color: white;
			}
			a:active, a:focus {
				color: $primary-colour;
			} 
			.separator {
				height: 30px;
				border-left: 1px solid rgba(255, 255, 255, 0.5);
				margin-top: 10px;
			}
		}
		.navbar-brand {
			margin-left: 0;
			padding: 12px 15px;
		}
	}
}

body {
	background: $body-bg;
}

.level1 {
	background: #FFFFFF;
	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.20);
	border-radius: 7px;
	padding: 30px;
	padding-bottom: 20px;
	margin-left: 5px;
	margin-right: 5px;
	margin-bottom: 20px;
}

/* Login ********************************************************************************************/

.login-box {
	text-align:center;
	.welcome-message {
		margin-top: 10px;
		text-align:center;
		color: $grey2;
		font-size: 1.1em;
		-webkit-font-smoothing:antialiased;
	}
}

/* Hub ********************************************************************************************/

.subheader {
	margin-bottom: 20px;
	margin-top: -20px;
	padding-top: 22px;
	font-family: $heading-font;
	color: white;
	font-size: 1.7em;
	-webkit-font-smoothing:antialiased;
	padding-bottom: 22px;
}

.subheader.AR {
	background-image: $ar-gradient;
}
.subheader.VR {
	background-image: $vr-gradient;
}
.subheader.QR {
	background-image: $qr-gradient;
}
.subheader.SJ {
	background-image: $sj-gradient;
}
.subheader.DM {
	background-image: $dm-gradient;
}


.practice-box {
	background: rgb(237,237,237);
	border-radius: 7px;
	margin-left: 5px;
	padding: 10px;
	padding-bottom: 20px;
	margin-right: 5px;
	margin-top: 20px;
	margin-bottom: 20px;
	text-align:center;
	h5 {
		text-align:center;
		font-family: $paragraph-font;
		color: gray;
		text-transform:uppercase;
	}
	.completed {
		font-size: 2.1em;
		font-weight: 600;
		-webkit-font-smoothing:antialiased;
		color: $grey1;
	}
	.total {
		font-size: 1.1em;
		font-weight: 600;
		-webkit-font-smoothing:antialiased;
		color: gray;
	}
	.avg {
		font-size: 2.1em;
		color: $grey1;
		font-weight: 600;
		-webkit-font-smoothing:antialiased;
	}

}
.button-row {
	text-align:center;
}
	button.standard-button {
	display: inline-block;
	margin: 0 auto;
	padding-top: 7px;
	padding-bottom: 9px;
	border-radius: 30px !important;
	width: 190px;
	border: none !important;
	background: $primary-colour !important;
	font-family: $heading-font;
	color: white !important;
	margin-top: 7px;
	font-size: 1.05em;
	-webkit-font-smoothing:antialiased;
	font-weight: 700 !important; 
	letter-spacing: -0.02em;
	text-transform: uppercase;
		.button-icon {
			margin-right: 6px;
		}
	}
	button.standard-button:hover {
		background: $primary-colour-darkened !important;
	}
	button.grey-button {
		background: $grey2 !important;
	}
	button.grey-button:hover {
		background: rgb(90,90,90) !important;
	}
	button.disabled-button {
		cursor: not-allowed;
	}
	button.disabled-button:hover {
		background: $grey2 !important;
	}
	button:focus {
		outline: none;
	}

.recent-question-wrapper {
	margin-top: 20px;
	background: #ededed;
	padding: 15px;
	border-radius: 5px;
	text-align:center;
	.element-wrapper {
		display: inline-block;
		height: 17px;
		margin-top: 2px;
	}
	.review-circle {
		width: 15px;
		height: 15px;
		border-radius: 20px;
		display: inline-block;
		margin: 0 auto;
	}
	.review-rectangle {
		width: 40px;
		height: 5px;
		background: white;
		display: inline-block;
		margin: 0 auto;
		margin-left:3px;
		margin-right:3px;
		margin-bottom: 5px;
		background: rgb(185,185,185);
	}
	.review-circle.correct {
		background: $green;
	}
	.review-circle.incorrect {
		background: $red;
	}
}

.hubprogress {
	.panel-heading {
		background: #EDEDED;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		padding-top: 12px;
		padding-bottom: 12px;
		padding-left: 30px;
	}
	.panelindex {
		color: white;
		position: relative;
		top: 12px;
		padding-top: 1px;
		text-align: center;
		left: -8px;
		display: block;
		height: 25px;
		width: 25px;
		font-weight:300;
		border-radius: 20px;
		background: $primary-colour;
	}
	.panel-group {
		margin-bottom: 0 !important;
	}
	.panel-title {
		font-family: $paragraph-font;
		font-weight: normal;
		font-size: 1.3em;
		text-align: left;
		color: $grey1;
		text-transform: none;
	}
	.panel {
		border-color: #ededed;
		border-radius: 6px !important;
		box-shadow: none;
	}
	.panel-body {
		border-top: 1px solid #ededed !important;
		padding-top: 25px;
	}
	.subtopics {
		padding-left: 15px;
	}
	.subtopics li {
		list-style:none;
		padding: 0;
		font-size: 1.15em;
		-webkit-font-smoothing:antialiased;
		margin: 0;
		margin-top: -6px;
		a {
			color:inherit;
		}
		a:hover {
			text-decoration: underline;
		}
	}
	.subtopics li.complete::before {
		content: " ";
	    width: 12px;
	    margin-right: 20px;
	    height: 12px;
	    display:inline-block;
	    border-radius: 10px;
	    background:$green;
	}
	.subtopics li.incomplete::before {
		content: " ";
	    width: 12px;
	    height: 12px;
	    margin-right: 20px;
	    display: inline-block;
	    border-radius: 10px;
	    background:$grey3;
	}
	.subtopics li.incomplete::after {
		content: "";
		display: block;
		height: 16px;
		width: 4px;
		background: $grey3;
		position: relative;
		left: 4px;
		top: -3px;
	}
	.subtopics li.complete::after {
		content: "";
		display: block;
		height: 14px;
		width: 4px;
		background: $green;
		position: relative;
		left: 4px;
		top: -3px;
	}
	.subtopics li:last-child::after {
		display: none !important;
	}
}

/* Training ********************************************************************************************/

.tutorial-wrapper {
	margin-left: -15px;
	margin-right: -15px;
	padding: 0;
	margin-top: 20px;
	.nextprev {
		font-size: 1.05em;
		margin-bottom: -5px;
	}
	.nextprev:hover {
		font-weight: 500;
		text-decoration: none;
	}
	.beforetitle {
		margin-top: 8px;
	}
}
.sidebar {
	height: 100%;
	padding:30px;
	.topic a {
		color: rgb(45,45,45);
	}
	.subtopics {
		padding-left: 15px;
	}
	.subtopics {
		margin-bottom: 15px;
		margin-top: 15px;
	}
	.topic {
		font-size: 1.4em;
		-webkit-font-smoothing:antialiased;
		margin-bottom: 10px;
	}
	.subtopics li {
		list-style:none;
		padding: 0;
		font-size: 1.15em;
		-webkit-font-smoothing:antialiased;
		margin: 0;
		margin-top: -6px;
		a {
		}
		a:hover {
			text-decoration: underline;
		}
	}
	.subtopics li.complete::before {
		content: " ";
	    width: 11px;
	    margin-right: 20px;
	    height: 11px;
	    display:inline-block;
	    border-radius: 10px;
	    background:$green;
	}
	.subtopics li.incomplete::before {
		content: " ";
	    width: 11px;
	    height: 11px;
	    margin-right: 20px;
	    display: inline-block;
	    border-radius: 10px;
	    background:$grey3;
	}
	.subtopics li.incomplete::after {
		content: "";
		display: block;
		height: 16px;
		width: 3px;
		background: $grey3;
		position: relative;
		left: 4px;
		top: -3px;
	}
	.subtopics li.complete::after {
		content: "";
		display: block;
		height: 14px;
		width: 3px;
		background: $green;
		position: relative;
		left: 4px;
		top: -3px;
	}
	.subtopics li:last-child::after {
		display: none !important;
	}
	.subtopics li.current::before {
		content: " ";
	    width: 11px;
	    height: 11px;
	    margin-right: 20px;
	    display: inline-block;
	    border-radius: 10px;
	}
	.subtopics li.current {
		font-weight: bold;
		a {
		}
	}
	.subtopics li:hover::before {
		content: " ";
	    width: 11px;
	    margin-right: 20px;
	    height: 11px;
	    display:inline-block;
	    border-radius: 10px;
	    background:#5ea8ec;
	}
}

.tutorial-body {
	padding:40px;
	border-left: 1px solid rgb(230,232,234);
	.tutorial-title {
		font-size: 2.5em;
		font-weight: bold;
		margin-top: 0;
	}
	.topic-title {
		margin-bottom: 0;
		font-size: 1.2em;
		margin-bottom:-5px;
		margin-top: 30px;
		-webkit-font-smoothing:subpixel-antialiased;
		text-transform: uppercase;
		color: gray;
	}
	* {
		font-family: $paragraph-font !important;
		-webkit-font-smoothing:antialiased;
		text-align:left;
		text-transform: none;
	}
	blockquote {
		font-size: 1em;
		font-style: italic;
	}
	p {
		font-size: 1.15em;
		line-height: 1.65em;
		margin-bottom: 20px;
	}
	li {
		font-size: 1.15em;
		line-height: 1.65em;
		padding-left: 12px;
	}
	h1 {
		font-size: 2em;
		font-weight: bold;
		text-transform: none;
		margin-top: 30px;
		margin-bottom: 15px;
	}
	h2 {
		font-size: 1.5em;
		font-weight: bold;
		margin-top: 20px;
		margin-bottom: 10px;
	}
	h3 {
		font-size: 1.25em;
		font-weight: bold;
		color: rgb(90,90,90);
		margin-top: 10px;
		margin-bottom: 5px;
	}
}

/* Home ********************************************************************************************/

.progressbox {
	display: inline-block;
	min-width: 320px;
	background: $body-bg;
	border-radius: 7px;
	margin-right: 20px;
	margin-bottom: 20px;
	height: 130px;
	h1 {
		margin: 0;
		margin-top: 5px;
		margin-bottom: 5px;
		font-size: 1.5em;
		font-weight: 500 !important;
		text-transform:none;
		color: $grey1;
		font-family: $paragraph-font;
	}
	h2 {
		margin: 0;
		margin-bottom: 4px;
		font-size: 1.1em;
		font-weight: normal;
		color: $grey2;
		text-transform: capitalize;
		-webkit-font-smoothing:subpixel-antialiased;
		text-align: left;
		font-family: $paragraph-font;
	}
	.topic-icon {
		width: 90px;
		height: 90px;
		display: inline-block;
		background: white;
		margin-top: 20px;
		margin-left: 20px;
		border-radius: 50px;
		padding-top: 5%;
		text-align:center;
		img {
			width: 65%;
		}
	}
	.topic-stats {
		height: 100px;
		margin-top: 15px;
		vertical-align: top;
		margin-left: 15px;
		display: inline-block;
	}
	button {
	height: 32px;
	border-radius: 30px !important;
	width: 170px;
	border: none !important;
	background: $primary-colour !important;
	font-family: $heading-font;
	color: white !important;
	margin-top: 7px;
	font-weight: 500; 
	text-transform: uppercase;
	}
	button:hover {
		background: $primary-colour-darkened !important;
	}
}

.tipbox {
	p {
		font-style: italic;
	}
}

.sidebox {
	text-align:center;
	p {
		margin-top: 10px;
		font-size: 1.1em;
		color: rgb(90,90,90);
		-webkit-font-smoothing:antialiased;
	}
}

/* Exam ********************************************************************************************/


/* Practice ********************************************************************************************/

html {
	height: 100%;
}

.summary-wrapper {
	margin-bottom: 100px;
}

.lowkey-button {
	color: white;
    border-radius: 20px;
    padding-bottom: 3px;
    padding-left: 10px;
	padding-right: 10px;
    font-weight: 300;
    border: none;
    font-size: 0.7em;
    font-weight: 400 !important;
    background: rgba(255, 255, 255, 0.3);
	font-family: 'Lato', sans-serif;
	margin-right: 8px;
	display: inline-block !important;
}

.set-title {
	margin-left: 8px;
}

.middle-mobile-padding {
	height: 60px;
	width: 100%;
}

.no-padding {
	padding: 0 !important;
}

.section-spacer-mobile {
	height: 80px;
}


@media(max-width: 768px) {
	.center-mobile {
		text-align: center !important;
		clear: both;
	}
	.lowkey-button-wrapper {
		float: none;
	}
}

@media(min-width: 768px) {
	.lowkey-button-wrapper {
		float: right;
	}
} 

.float-left {
	float:left;
}

.float-right {
	float: right;
}

.practice-main-title {
	font-size: 1.4em;
	text-align: left;
}

.practice-nav-row {
	height: 45px;
}

.practice-interface {
	height: 100%;
	background: white;
	#mount {
		height: 100%;
	}
	.navbar {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 10;
	}
	.navbar-brand {
		padding-top: 5px !important;
		padding-left: 0 !important;
	}
	.logo-icon {
		width :50px;
		display: inline-block;
	}
	.practice-title {
		color: white;
		-webkit-font-smoothing:antialiased;
		margin-left: 15px;
		vertical-align:sub;
		display: inline-block;
	}
	.practice-options {
		text-align:right;
		float: right;
		display: inline-block;
		margin: 0;
		li {
			color: white;
			font-size: 2em;
			display: inline-block;
			float: right;
			margin-left: 13px;
			margin-right: 13px;
		}
		li#timer {
			letter-spacing:-0.02em;
			margin-top:5px;
			font-size: 1.6em;
			-webkit-font-smoothing:antialiased;
		}
		.exit {
			color: rgba(255,255,255,0.5);
			cursor: pointer;
		}
		.exit:hover {
			color: red;
		}
		.calculator {
			font-size: 1.75em;
			color: rgba(255,255,255,0.5);
			cursor: pointer;
			.fa {
				vertical-align:middle;
			}
		}
		.calculator:hover {
			color: $lightblue;
		}
	}
	.react-wrapper {
		height: 100%;
		width: 100%;
		position:absolute;
	}
	.practice-subheader {
		background: #40a3ff;
		margin: 0;
		color: white;
		font-family: $heading-font;
		font-size: 1.4em;
		padding-top: 10px;
		padding-bottom: 10px;
		text-transform: uppercase;
		.next-set-button {
			background: $green;
		    border: none;
		    border-radius: 20px;
		    padding-left: 20px;
		    padding-right: 20px;
		    float: right;
		    padding-top: 3px;
		    -webkit-font-smoothing: antialiased;
		    text-transform: uppercase;
		    font-size: 0.75em;
		    font-weight: bold;
		}
		.next-set-button:hover {
			background:$green-hover;
		}
	}
	.practice-wrapper {
		background: white;
		height: 100%;
		width: 100%;
		.container {
			height: 100%;
		}
		.row {
			height: 100%;
		}
	}
	.data-column {
		padding-top: 20px;
		font-size: 1.15em;
		-webkit-font-smoothing:antialiased;
		line-height: 1.6;
		.inner {
			max-width: 675px;
			border-left: 6px solid rgb(225,225,225);
			padding-left: 20px;
			margin-bottom: 100px;
		}
	}
	.question-column {
		border-left: 10px solid #40a3ff;
		height: 100%;
		padding-left: 22px;
		.flag-icon {
			color: $red;
			margin-right:8px;
		}
		.question-part {
			font-weight: bold;
			color: $grey2;
			text-transform: uppercase;
			font-size: 1em;
			margin-top: 20px;
			margin-bottom: 5px;
			-webkit-font-smoothing:antialiased;
		}
		.question {
			font-size: 1.35em;
			font-weight: bold;
			color: $grey1;
			-webkit-font-smoothing:antialiased;
		}
		ol {
			padding-left: 15px;
			margin-top: 15px;
		}
		.option {
			font-size: 1.1em;
			cursor: pointer;
			line-height: 1.25;
			margin-top: 10x;
			margin-bottom: 10px;
			vertical-align:middle;
			-webkit-font-smoothing:antialiased;
		}
		.option:hover {
			.bullet.unselected {
				background: rgb(180,180,180);
			}
		}
		.bullet {
			border-radius: 15px;
			margin-right: 10px;
			width: 15px;
			height: 15px;
			background: white;
			border: 4px solid white;
			box-shadow: 0px 0px 2px black, 0px 0px 2px gray;
			display: inline-block;
		}
		.selected {
			background: #40a3ff;
		}
	}
	@media (max-width: 992px) {
		.question-column {
			border-left: 0;
			border-top: 1px solid $grey3;
			padding-left: 0;

		}
		.data-column {
			padding-left: 0;
			padding-right: 0;
			margin-bottom: 20px;
		}
	}
	.middle {
		position:absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		padding-top: 63px;
	}
	.practice-nav {
		position:fixed;
		z-index: 10;
		bottom: 0;
		background: #40a3ff;
		left: 0;
		width: 100%;
		padding: 0;
	}
	.next-button, .previous-button {
		padding-top: 15px;
		cursor: pointer;
		padding-bottom: 15px;
		min-width: 100px;
		color: white;
		font-family: $heading-font;
		text-transform: uppercase;
		text-align: center;
	}
	.next-button {
		background: $green;
		float: right;
	}
	.next-button:hover {
		background: $green-hover;
	}
	.previous-button {
		float: left;
		background: $red;
	}
	.previous-button:hover {
		background: $red-hover;
	}
	.progress-wrapper {
		margin: 0 auto;
		text-align:center;
		margin-top: 17px;
		padding-bottom: 10px;
	}
	.progress-circle {
		width: 17px;
		height: 17px;
		border-radius: 20px;
		background: white;
		display: inline-block;
		margin: 0 auto;
	}
	.progress-circle.active {
		width: 17px;
		height:17px;
		border: 4px solid white;
		background: #1483ea;
		border-radius: 20px;
	}
	.progress-circle.answered {
		width: 17px;
		height:17px;
		border: 4px solid white;
		background: orange;
		border-radius: 20px;
	}
	.progress-circle.answered.active {
		background: #1483ea;
		border-color: white;
	}
	.progress-rectangle {
		width: 50px;
		height: 7px;
		background: white;
		display: inline-block;
		margin: 0 auto;
		margin-bottom: 5px;
		margin-left: -1px;
		margin-right: -1px;
	}
	.progress-rectangle.visited {
		background: orange;
	}
	.progress-bit {
		width: 100%;
		margin-top: 13px;
		text-align:center;
		white-space: nowrap;
		overflow: auto;
		.progress-question {
			cursor: pointer;
			padding-top: 3px;
			background: white;
			width: 25px;
			height: 25px;
			font-weight: bold;
			font-family: $heading-font;
			border-radius: 25px;
			text-align:center;
			display: inline-block;
			margin-left: 3px;
			margin-right: 3px;
			color: $grey2;
			font-size: 0.96em;
		}
		.progress-question:hover {
			background: rgb(220,220,220);
		}
		.progress-question.current {
			background: rgb(220,220,220);
		}
		.progress-question.answered {
			color: #2f8ade;
		}
		.progress-question.flagged {
			background: $red;
			color: white;
			-webkit-font-smoothing:antialiased;
		}

	}
	.review-modal {
		text-align:center;
		padding-top: 25px;
		text-align:center;
		.modal-footer {
			padding-bottom: 30px;
			text-align:center;
			border: none;
		}
		.modal-header {
			border: none;
		}
		.modal-title {
			margin-top: 15px;
		}
		p {
			font-size: 1.1em;
			-webkit-font-smoothing:antialiased;
			width: 80%;
			margin: 0 auto;
			margin-bottom: 0px;
		}
		textarea {
			width: 80%;
			display: block;
			margin: 0 auto;
			font-size: 1.1em;
		}
		.review-progress-wrapper {
			margin: 0 auto;
			.review-circle {
				width: 17px;
				height: 17px;
				border-radius: 20px;
				display: inline-block;
				margin: 0 auto;
			}
			.review-rectangle {
				width: 50px;
				height: 7px;
				background: white;
				display: inline-block;
				margin: 0 auto;
				margin-left:3px;
				margin-right:3px;
				margin-bottom: 5px;
				background: rgb(185,185,185);
			}
			.review-circle.correct {
				background: $green;
			}
			.review-circle.incorrect {
				background: $red;
			}
		}
	}
	.summary-column {
		text-align:center;
		padding-top: 30px;
		p {
			font-size: 1.15em;
			line-height: 1.52;
			-webkit-font-smoothing:antialiased;
		}
		h1 {
			margin-bottom: 20px;
		}
	}
	.summary-set {
		display: inline-block;
		margin: 12px;
	}
	.summary-question {
		cursor: pointer;
		display: inline-block;
		width: 40px;
		font-family: $heading-font;
		font-weight: bold;
		height: 40px;
		padding-left: 1px;
		font-weight: bold;
		border-radius: 40px;
		text-align:center;
		padding-top: 10px;
		margin: 0 auto;
		font-size:1.15em;
		background: gray;
		color: white;
		margin: 7px;
		-webkit-font-smoothing:antialiased;
	}
	.summary-question.unanswered {
		background: $orange;
	}
	.summary-question.answered {
		background: $lightblue;
	}
	.summary-question.flagged {
		background: $red;
	}
			.solution-correct {
			color:green;
		}
		.solution-incorrect {
			color: $red;
		}
	.solution-box {
		background: rgb(230,230,230);
		border-bottom: 1px solid rgb(200,200,200);
		padding-top: 15px;
		-webkit-font-smoothing:antialiased;
		.solution-header {
			padding-left: 15px;
			cursor: pointer;
			font-size: 1.2em;
			margin-bottom: 15px;
			.was_user_correct {
				display: inline-block;
				padding: 3px;
				margin-left: 10px;
				font-size: 0.88em;
				text-transform: uppercase;
			}
			.prompt {
				display: inline-block;
				color: rgba(0,0,0,0.5);
				margin-left: 10px;
				width: 172px;
			}
			.fa {
				font-size: 0.9em;
				color: rgba(0,0,0,0.5);
			}
		}
		.solution-header:hover {
			.prompt {
				color: rgba(0,0,0,0.9);
			}
			.fa {
				color: rgba(0,0,0,0.9);
			}
		}
		.solution-body {
			padding-left: 15px;
			.solution-text {
				font-size: 1.15em;
				line-height: 1.58;
				padding-bottom: 20px;
				max-width: 800px;
				border-bottom: 1px solid gray;
			}
			.report {
				display:inline-block;
				margin-top: 10px;
				margin-bottom: 15px;
				cursor: pointer;
				font-weight: bold;
			}
			.report:hover {
				text-decoration: underline;
			}
		}
	}

}

/** Account **/

.account-header {
	background: $pink-gradient;
}

.account-title {
	font-family: $heading-font;
	text-transform:uppercase;
	font-size: 1.8em;
	-webkit-font-smoothing:antialiased;
	font-weight: 700 !important;
	letter-spacing: -0.01em;
	text-align:center;
}

.account-pitch {
	font-family: $paragraph-font;
	font-size: 1.2em;
	line-height:1.6;
	max-width: 900px;
	margin: 0 auto;
	text-align:center;
	margin-top: 15px;
	margin-bottom: 30px;
	-webkit-font-smoothing:antialiased;
}

.subscription-box {
	background: rgb(243,243,243);
	padding:20px;
	padding-left: 30px;
	padding-right: 30px;
	border-radius: 7px;
}

.footer {
	text-align:center;
	-webkit-font-smoothing:antialiased;
	margin-top: 10px;
	margin-bottom: 10px;
}


